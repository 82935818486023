import React from "react";
import Page from '../components/page'
import BgAppIcons from '../components/backgroundAppsIcon'
import Intro from "../components/intro";
import AppList from '../components/appList'
import Blogs from "../components/blogs";
import Npm from "../components/npm";
import FunProjects from "../components/funProjects";


export default function () {
  return (
    <Page>
      <BgAppIcons />
      <Intro />
      <AppList />
      <Blogs />
      <Npm />
      <FunProjects />
    </Page>
  );
}
